// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$my-primary : mat.define-palette(mat.$yellow-palette, 200);
$my-primary : mat.define-palette(mat.$grey-palette, 800);
$my-accent  : mat.define-palette(mat.$yellow-palette, 600);
//$agent-app-accent: mat.define-palette(mat.$deep-orange-palette, 500);

// The warn palette is optional (defaults to red).
$my-warn    : mat.define-palette(mat.$red-palette, 500);

$my-typography: mat.define-typography-config(
  $font-family: "'Pixelify Sans'",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$my-theme   : mat.define-dark-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
  typography: $my-typography,
  density: 0
));

$my-custom-level: mat.define-typography-level(
  $font-family: Pixelify Sans,
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-theme);

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/material-icons.woff2) format('woff2');
  font-display: swap;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Pixelify Sans';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/PixelifySans-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Pixelify Sans';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/PixelifySans-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Pixelify Sans';
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/PixelifySans-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Pixelify Sans';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/PixelifySans-Bold.ttf);
  font-display: swap;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: "Pixelify Sans", "Helvetica Neue", sans-serif;
  /* Add !important to overwrite all elements */
}

*::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #E0E0E0;
}

*::-webkit-scrollbar-thumb {
  background-color: #757575;
  //border-radius: 20px;
  //border: 3px solid orange;
}

.font-logo {
  font-family: 'Pixelify Sans', Roboto, sans-serif !important;
  font-size: xx-large !important;
}

.center-text {
  text-align: center;
}

.align-justify {
  text-align: justify;
  padding: 0 10px 0 10px
}
